<!-- 创建团队 -->
<template>
  <div>
    <p>编程一班</p>
    <el-table :data="tableData" border stripe style="width: 100%">
      <el-table-column prop="state" label="个人ID" align="center">
      </el-table-column>
      <el-table-column prop="name" label="姓名" align="center" width="160">
        <template slot-scope="scope">
          <span style="color: #40b292">
            {{ scope.row.name }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="unmber" label="性别" align="center">
      </el-table-column>

      <el-table-column prop="time" label="排名" align="center" width="160">
      </el-table-column>
      <el-table-column prop="jurisdiction" label="出勤情况" align="center">
      </el-table-column>
    </el-table>




    <el-table :data="teamData" border style="width: 100%; margin-top: 3%">
      <el-table-column prop="state" label="科目" align="center">
      </el-table-column>

      <el-table-column prop="name" label="作业" align="center">
      </el-table-column>
      <el-table-column prop="unmber" label="成绩" align="center">
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    //这里存放数据
    return {
      tableData: [
        {
          state: "222",
          name: "张文",
          unmber: '男',
          time: 1,
          jurisdiction: "全勤",
        },
      ],
      teamData: [
        {
          state: "新人编程",
          name: "已完成",
          unmber: 12,
         
        },
        {
          state: "新人编程",
          name: "已完成",
          unmber: 12,
         
        },{
          state: "新人编程",
          name: "已完成",
          unmber: 12,
         
        },{
          state: "新人编程",
          name: "已完成",
          unmber: 12,
         
        },{
          state: "新人编程",
          name: "已完成",
          unmber: 12,
         
        },
      ],
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  //方法集合
  methods: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
};
</script>
<style  lang='less' scoped>
P {
  font-size: 24px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #2d2d2d;
  line-height: 33px;
  margin-bottom: 1.5%;
}
::v-deep {
  .el-table th {
    background-color: #00957e;
    font-size: 14px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #ffffff;
    line-height: 20px;
    &:last-child {
      border-right: 0;
    }
  }
  .el-table td {
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #1d1d1d;
  }
  .el-table td,
  .el-table th {
    padding: 10.5px 0;
    font-size: 14px;
  }
}
</style>